<template>
  <div>
    <div class="header-login wf-section">
      <div class="container-header-login w-container">
        <div class="w-layout-grid grid-header-login">
          <div id="w-node-_03ddceca-a534-b55e-e4cc-69b8261c6240-261c623d" class="zone-logo">
            <a href="https://pautions.fr" target="_blank">
              <img :src="require('@/assets/images/logo_pautions.svg')" loading="lazy" width="120" alt="" />
            </a>
          </div>
          <div id="w-node-_584d43b4-e90f-3956-cfd0-aa02149a422e-261c623d" class="zone-call">
            <a href="tel:+33970386826" class="link-block w-inline-block"><img :src="require('@/assets/images/ic_tel.svg')" loading="lazy" width="20" alt="" /></a>
            <p id="w-node-_03ddceca-a534-b55e-e4cc-69b8261c6243-261c623d" class="paragraph info">Besoin d’aide pour vous connecter ? Appelez notre service client au 09 70 38 68 26.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="zone-login wf-section">
      <div class="container-login w-container">
        <div class="w-layout-grid grid-login">
          <div id="w-node-_9cff9bef-e8f5-03b3-5b12-fb9ebf49daa0-65a4bdd8" class="div-login">
            <img :src="require('@/assets/images/logo_pautions.svg')" loading="lazy" width="150" alt="Pautions logo" class="image" />
            <p class="paragraph-4">accédez à votre compte</p>
            <div class="login-form w-form">
              <form id="wf-form-LOGIN-FORM" name="wf-form-LOGIN-FORM" data-name="LOGIN FORM" method="get" class="form" v-if="event === 'login'">
                <input type="text" class="champ-login w-input" maxlength="256" name="email" data-name="email" placeholder="email" id="email" required="" v-model="user.email"/>
                <input type="password" class="champ-login w-input" maxlength="256" name="mot-de-passe" data-name="mot de passe" placeholder="mot de passe" id="mot-de-passe" required="" v-model="user.password" />
                <a @click="login()" class="submit-button w-button">se connecter</a>
              </form>
              <form id="wf-form-LOGIN-FORM" name="wf-form-LOGIN-FORM" data-name="LOGIN FORM" method="get" class="form" v-if="event === 'forgot'">
                <p class="paragraph-4">merci de saisir votre adresse mail</p>
                <input type="email" class="champ-login w-input" maxlength="256" name="mot-de-passe" data-name="mot de passe" placeholder="email" id="mot-de-passe" required="" v-model="user.email" />
                <a @click="forgot()" class="submit-button w-button">reinitialiser</a>
              </form>
              <form id="wf-form-LOGIN-FORM" name="wf-form-LOGIN-FORM" data-name="LOGIN FORM" method="get" class="form" v-if="event === 'check_email'">
                <p class="paragraph-4">La procédure a été envoyé, merci de verifier votre email.</p>
              </form>
            </div>
            <p class="mot-de-passe-oublie" @click="event = 'forgot'" v-if="event === 'login'">mot de passe oublié ?</p>
            <p class="mot-de-passe-oublie" @click="event = 'login'" v-if="event === 'forgot'">se connecter ?</p>
            <p class="mot-de-passe-oublie" @click="event = 'login'" v-if="event === 'check_email'">se connecter ?</p>
          </div>
          <div id="w-node-ea80a0a7-848b-0674-dc23-00e09a5e212c-65a4bdd8" class="div-img-login">
            <img :src="require('@/assets/images/man_jump_crm_login_light_2.jpg')" loading="lazy" alt="" height="600" sizes="(max-width: 694px) 90vw, (max-width: 991px) 625px, 60vw" class="image-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Login",
  data(){
    return {
      event:'login',
      user:{
        email:null,
        password: null
      }
    }
  },
  methods: {
    login() {
      if(this.user.email === null && this.user.password === null){
        return false;
      }
      this.$store.api.ajax('/authorization/create', this.user, (res) => {
        if(res.status === true){
          this.$store.api.saveToken(res.data.token);
          this.$store.api.saveUser(res.data);
          this.$router.push({path:'/accueil'});
        }
      })
    },
    forgot(){
      if(this.user.email === null || this.user.email.indexOf('@') === -1){
        this.$toastr.e("Email invalide !");
        return false;
      }
      this.$store.api.ajax('/users/forgot', this.user, res => {
        if(res.status === true){
          this.event = 'check_email';
        }
      })
    }
  },
  components: {
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-4";
  },
};
</script>
<style media="screen">
  .submit-button.w-button:hover{
    color: white !important;
  }
</style>
